import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const ToolTip = ({ children, content, ...rest }) => {
  return (
    <Tippy appendTo="parent" content={content} theme="backdrop" {...rest}>
      {children}
    </Tippy>
  );
};

ToolTip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default ToolTip;
